/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-11-13",
    versionChannel: "nightly",
    buildDate: "2023-11-13T00:14:23.579Z",
    commitHash: "11c5a68452e53858871a0c3567a3c8368d6596be",
};
